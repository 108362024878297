module.exports = [{
      plugin: require('/Users/karldanninger/dev/motobike-jam/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-89071039-3"},
    },{
      plugin: require('/Users/karldanninger/dev/motobike-jam/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://moto.bike"},
    },{
      plugin: require('/Users/karldanninger/dev/motobike-jam/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/karldanninger/dev/motobike-jam/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/karldanninger/dev/motobike-jam/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
